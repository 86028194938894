import { useState, useEffect, useCallback } from "react";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
  Filter,
} from "react-bs-datatable";
import { Button, Tooltip, Input, Box, Typography } from "@mui/material";
import { Col, Row, Table } from "@themesberg/react-bootstrap";
import "../../scss/dashboard.page.scss";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clientAdapter from "../../lib/clientAdapter";
import moment from "moment-timezone";
import { orderBy } from "lodash";
import Toggles from "../components/Leadpage-Modals/AppointmentModal";
import Sms from "../components/Leadpage-Modals/SmsModal";
import Whatsapp from "../components/Leadpage-Modals/WhatsappModal";
import View from "../components/Leadpage-Modals/ViewModal";
import NewLead from "../components/Dropdowns-Leadspage/Buttonleadtable";
import DropdownLeadsource from "../components/Dropdowns-Leadspage/Dropdown-leadsource";
import DropdownLeadstatus from "../components/Dropdowns-Leadspage/Dropdown-leadstatus";
import { SkeletonLoader } from "../../components/loader/SkeletonLoader";
import { leadStatus } from "../../data/leadStatus";
import { useLocation } from "react-router-dom";

const header = [
  { title: "Created", prop: "dateCreated", isSortable: true },
  { title: "Customer Name", prop: "fullName", isSortable: true },
  { title: "Status", prop: "status" },
  { title: "Next Follow Up On", prop: "followupDate", isSortable: true },
  { title: "Lead Source", prop: "source" },
  { title: "First Bill Value", prop: "firstBillValue" },
  { title: "Actions", prop: "actions" },
];

// Then, use it in a component.
const Leadtable = () => {
  const location = useLocation();
  const [leadCount, setLeadCount] = useState(0);
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [sortState, setSortState] = useState({});
  const [filterInput, setFilterInput] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState();
  const [filterStatus, setFilterStatus] = useState("");
  const [source, setSource] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [leadListLoading, setLeadListLoading] = useState(false);
  const queryParameters = new URLSearchParams(location.search);
  const display = queryParameters.get("display");

  const getStatusClass = (statusId) => {
    let className = ``;
    switch (statusId) {
      case 1:
        className = "validated-box";
        break;
      case 2:
        className = "prospective-box";
        break;
      case 3:
        className = "hot-box";
        break;
      case 4:
        className = "converted-box";
        break;
      case 6:
        className = "cold-box";
        break;
      case 7:
        className = "connect-box";
        break;
      default:
        className = "";
    }
    return `source-wrapper ${className}`;
  };

  const getLeadData = async (
    page,
    limit,
    sortState,
    name,
    status,
    source,
    startDate,
    endDate,
    followupDateStart,
    followupDateEnd
  ) => {
    setLeadListLoading(true);
    try {
      const leRes = await clientAdapter.getLocationLeads(
        page,
        limit,
        sortState,
        name,
        status,
        source,
        startDate,
        endDate,
        followupDateStart,
        followupDateEnd
      );
      const leadRes = leRes;
      setLeadListLoading(false);
      if (sortState) {
        leadRes.leads = orderBy(leadRes.leads, sortState.prop, sortState.order);
      }
      leadRes?.leads?.map((l) => {
        l.fullName = `${l.firstName || ""} ${l.lastName || ""}`;
        l.status = (
          <span className={getStatusClass(l?.leadStatus?.id)}>
            {l.leadStatus?.status}
          </span>
        );
        l.source = l.leadSource ? l.leadSource.source : "NA";
        l.dateCreated = moment(l.dateCreated).format("D-MMM-YY hh:mm A");
        l.followupDate = l.followupDate
          ? moment(l.followupDate).format("Do MMM YYYY")
          : "NA";
        l.firstBillValue = `Rs. ${0}`;
        l.actions = [
          <Toggles data={l} />,
          <View data={l} />,
          <Whatsapp data={l} />,
          <Sms data={l} />,
        ];
      });
      setFilteredDataLength(rowsPerPage);
      setData(leadRes.leads);
      setLeadCount(leadRes.count);
      setMaxPage(Math.ceil(leadRes.count / rowsPerPage));
    } catch (err) {
      setLeadListLoading(false);
    }
  };

  const onSortChange = useCallback((nextProp) => {
    setSortState(nextProp);
  }, []);

  const onPaginationChange = useCallback((nextPage) => {
    setCurrentPage(nextPage);
  }, []);

  const onRowsPerPageChange = useCallback((rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    getLeadData(
      currentPage,
      rowsPerPage,
      sortState,
      filterInput,
      filterStatus,
      source,
      startDate,
      endDate,
      display === "Today"
        ? moment(new Date()).subtract(1, "day").format("YYYY-MM-DD")
        : "",
      display === "Today"
        ? moment(new Date()).subtract(1, "day").format("YYYY-MM-DD")
        : ""
    );
  }, [sortState, currentPage, rowsPerPage, display]);

  useEffect(() => {
    if (searchClicked) {
      getLeadData(
        currentPage,
        rowsPerPage,
        sortState,
        filterInput,
        filterStatus,
        source,
        startDate,
        endDate,
        display === "Today"
          ? moment(new Date()).subtract(1, "day").format("YYYY-MM-DD")
          : "",
        display === "Today"
          ? moment(new Date()).subtract(1, "day").format("YYYY-MM-DD")
          : ""
      );
      setSearchClicked(false);
    }
  }, [searchClicked]);

  const onFilter = () => {
    setFilter(filter);
  };

  const handleFilter = (e) => {
    setFilterInput(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleFilterChange = (selectedStatus) => {
    setFilterStatus(selectedStatus);
  };

  const handleSourceChange = (selectedSource) => {
    setSource(selectedSource);
  };

  useEffect(() => {}, [source]);

  const handleFilters = (e) => {
    setSearchClicked(true);
  };

  return (
    <>
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center">
        <Col className="d-block mb-4 mb-md-0 col-2">
          <h3 className="p-3">Leads</h3>
        </Col>
        <Col className="d-flex flex-column justify-content-end align-items-end leadpage-Btn">
          <NewLead />
        </Col>
      </Row>
      {leadListLoading ? (
        <div style={{ width: "100%" }}>
          <SkeletonLoader />
        </div>
      ) : (
        <DatatableWrapper body={data} headers={header} isControlled>
          <section className="m-0">
            <div className="d-flex justify-content-between w-100 m-0">
              <Filter
                controlledProps={{
                  filter: "searchbar",
                  onFilter,
                }}
              />
              <input
                className="input-search"
                type="text"
                width="25%"
                placeholder="Search"
                name="searchbar"
                value={filterInput}
                onChange={(e) => handleFilter(e)}
              />
              <DropdownLeadstatus onChange={handleFilterChange} />
              <DropdownLeadsource onChange={handleSourceChange} />
              <Tooltip title="Select Start Date" placement="top">
                <Input
                  size="small"
                  type="date"
                  style={{
                    border: "1px solid lightGray",
                    borderRadius: "4px",
                  }}
                  disableUnderline={true}
                  pattern="one"
                  value={startDate}
                  onChange={(e) => handleStartDate(e)}
                />
              </Tooltip>
              <Tooltip title="Select End Date" placement="top">
                <Input
                  type="date"
                  pattern="one"
                  style={{
                    border: "1px solid lightGray",
                    borderRadius: "4px",
                  }}
                  disableUnderline={true}
                  value={endDate}
                  onChange={(e) => handleEndDate(e)}
                />
              </Tooltip>
              <Button
                className=" search-leads"
                style={{ border: "1px solid lightGray" }}
                onClick={handleFilters}
              >
                <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
              </Button>
            </div>
          </section>
          <hr />
          <div className="row-box  col-md-12 col-lg-12">
            <div className="lead-status clearfix">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  sx={{ width: "85%" }}
                >
                  {leadStatus.map((i, x) => {
                    return (
                      <Box key={i.id} display={"flex"} alignItems={"center"}>
                        <div className={i.className} />
                        <Typography fontSize={14}>{i.status}</Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Box>
                  <span
                    style={{
                      backgroundColor: "lightgray",
                      color: "black",
                      border: " 1px solid gray",
                      padding: "5px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "50%",
                    }}
                  >
                    {leadCount}
                  </span>
                </Box>
              </Box>
            </div>
          </div>
          <Table
            className="data-tables col-sm-12 col-md-4 col-lg-10 "
            hover="true"
            responsive="true"
            width="auto"
            striped="true"
            style={{
              cursor: "pointer",
              borderBottom: "1px solid lightGray",
              borderRadius: "10px",
              marginTop: "15px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            <TableHeader
              controlledProps={{
                sortState,
                onSortChange,
                filteredDataLength,
              }}
            />
            <TableBody />
          </Table>
          <Row>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-end mb-2 mb-sm-0"
            >
              <PaginationOptions
                controlledProps={{
                  filteredDataLength,
                  onRowsPerPageChange,
                  rowsPerPageOptions: [5, 10, 15, 20],
                  rowsPerPage,
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Pagination
                controlledProps={{ currentPage, maxPage, onPaginationChange }}
              />
            </Col>
          </Row>
        </DatatableWrapper>
      )}
    </>
  );
};

export default Leadtable;
