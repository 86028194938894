import apiClient from "./apiClient";

const loginEmployee = async (data) => {
  const response = await apiClient.post("/auth/login-employee", data);
  return response.json();
};

const resetEmail = async (data) => {
  const response = await apiClient.post(`/auth/reset-email`, data);
  return response.status;
};

const resetPassword = async (data) => {
  const response = await apiClient.post("/auth/reset-password", data);
  return response.status;
};

const getUserLocations = async () => {
  const response = await apiClient.get("/users/get-locations");
  return response.json();
};

const setUserLocation = async (data) => {
  const response = await apiClient.get(`/auth/set-location/${data}`);
  return response.json();
};

const dashboardSales = async () => {
  const response = await apiClient.get(`/sales/dashboard`);
  return response.json();
};

const getLocationLeads = async (
  page,
  limit,
  sortState,
  name,
  status,
  source,
  startDate,
  endDate,
  followupDateStart,
  followupDateEnd
) => {
  let filters = ``;
  if (name) {
    filters += `&name=${name}`;
  }
  if (source) {
    filters += `&source=${source}`;
  }
  if (status) {
    filters += `&status=${status}`;
  }
  if (startDate) {
    filters += `&startDate=${startDate}`;
  }
  if (endDate) {
    filters += `&endDate=${endDate}`;
  }
  if (followupDateStart) {
    filters += `&followupDateStart=${followupDateStart}`;
  }
  if (followupDateEnd) {
    filters += `&followupDateEnd=${followupDateEnd}`;
  }
  const response = await apiClient.get(
    `/leads/?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const getSummaryGraph = async (startDate, endDate) => {
  const response = await apiClient.get(
    `/sales/summary-graph?startDate=${startDate}&endDate=${endDate}`
  );
  return response.json();
};

const getLeadFeedback = async (leadId, page, limit) => {
  const response = await apiClient.get(
    `/leads/feedback/${leadId}?page=${page}&limit=${limit}`
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    return null;
  }
};

const getCustomerData = async (page, limit, name, source, gender) => {
  let filters = ``;
  if (name) {
    filters += `&name=${name}`;
  }
  if (source) {
    filters += `&source=${source}`;
  }
  if (gender) {
    filters += `&gender=${gender}`;
  }
  const response = await apiClient.get(
    `/customers/?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const getCustomerDataByName = async (page, limit, name) => {
  const response = await apiClient.get(
    `/customers/?page=${page}&limit=${limit}&name=${name}`
  );
  return response.json();
};

const getLeadSource = async () => {
  const response = await apiClient.get(`/leads/sources`);
  return response.json();
};

const getLeadStatus = async () => {
  const response = await apiClient.get(`/leads/statuses`);
  return response.json();
};

const getCustomerPhone = async (mobile, data) => {
  const response = await apiClient.get(
    `/customers/search/?phone=${mobile}`,
    data
  );
  if (response.status === 200) {
    if (response) {
      return await response.json();
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const addItemToAppointmentSale = async (id, data) => {
  const response = await apiClient.post(`/sales/sale-items/${id}`, data);
  return response;
};

const addItemKitToAppointmentSale = async (id, data) => {
  const response = await apiClient.post(`/sales/sale-item-kits/${id}`, data);
  return response;
};

const deleteItemFromAppointmentSale = async (saleId, itemId) => {
  const response = await apiClient.delete(
    `/sales/appointment/${saleId}/sale-item/${itemId}`
  );
  return response;
};

const deleteItemKitFromAppointmentSale = async (saleId, itemId) => {
  const response = await apiClient.delete(
    `/sales/appointment/${saleId}/sale-item-kit/${itemId}`
  );
  return response;
};

const completeAppointmentSaleBySaleId = async (id, data) => {
  const response = await apiClient.patch(`/sales/${id}`, data);
  return response;
};

const addItemToSuspendSale = async (id, data) => {
  const response = await apiClient.post(`/sales/sale-items/${id}`, data);
  return response;
};

const addItemKitToSuspendSale = async (id, data) => {
  const response = await apiClient.post(`/sales/sale-item-kits/${id}`, data);
  return response;
};

const deleteItemFromSuspendSale = async (saleId, itemId) => {
  const response = await apiClient.delete(
    `/sales/${saleId}/sale-item/${itemId}`
  );
  return response;
};

const deleteItemKitFromSuspendSale = async (saleId, itemId) => {
  const response = await apiClient.delete(
    `/sales/${saleId}/sale-item-kit/${itemId}`
  );
  return response;
};

const completeSuspendSaleBySaleId = async (id, data) => {
  const response = await apiClient.patch(`/sales/${id}`, data);
  return response;
};

const changeDetachCustomer = async (id, data) => {
  const response = await apiClient.patch(`/sales/${id}/customer`, data);
  return response;
};

const getFamilyCardbyLocation = async (page, limit, number) => {
  let filters = ``;
  if (number) {
    filters += `&number=${number}`;
  }
  const response = await apiClient.get(
    `/familycards?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const getGiftCardbyLocation = async (page, limit, number) => {
  let filters = ``;
  if (number) {
    filters += `&number=${number}`;
  }
  const response = await apiClient.get(
    `/giftcards?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const getCouponById = async (id) => {
  const response = await apiClient.get(`/coupons/${id}`);
  return response.json();
};

const getFamilyCardDetailsbyId = async (id) => {
  const response = await apiClient.get(`/familycards/${id}`);
  return response.json();
};

const getGiftCardDetailsbyId = async (id) => {
  const response = await apiClient.get(`/giftcards/${id}`);
  return response.json();
};

const getCouponbyLocation = async (page, limit, number) => {
  let filters = ``;
  if (number) {
    filters += `&number=${number}`;
  }
  const response = await apiClient.get(
    `/coupons?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const getServicesbyLocation = async (page, limit, name) => {
  let filters = ``;
  if (name) {
    filters += `&name=${name}`;
  }
  const response = await apiClient.get(
    `/items?page=${page}&limit=${limit}&isService=${true}${filters}`
  );
  return response.json();
};

const getRetailProductbyLocation = async (page, limit, name, category) => {
  let filters = ``;
  if (name) {
    filters += `&name=${name}`;
  }
  if (category) {
    filters += `&category=${category}`;
  }
  const response = await apiClient.get(
    `/items?page=${page}&limit=${limit}&isService=${false}${filters}`
  );
  return response.json();
};

const getItemsbyLocation = async (page, limit, name) => {
  let filters = ``;
  if (name) {
    filters += `&name=${name}`;
  }
  const response = await apiClient.get(
    `/itemkits?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const searchItems = async (keyword, signal) => {
  const response = await apiClient.get(
    `/items/search?keyword=${keyword}`,
    signal
  );
  return response.json();
};

const searchItemsWithoutSignal = async (keyword) => {
  const response = await apiClient.get(`/items/search?keyword=${keyword}`);
  return response.json();
};

const searchItemsAndItemKits = async (keyword, signal) => {
  const response = await apiClient.get(
    `/sales/item-search?keyword=${keyword}`,
    signal
  );
  return response.json();
};

const getLocationData = async (id) => {
  const response = await apiClient.get(`/locations/${id}`);
  return response.json();
};

const createEmployee = async (data) => {
  const response = await apiClient.post(`/users/create-employee`, data);
  return response.status;
};

const createSupplier = async (date) => {
  const response = await apiClient.post(`/suppliers`, date);
  return response.json();
};

const updateEmployee = async (employeeId, data) => {
  const response = await apiClient.patch(
    `/users/employee/${Number(employeeId)}`,
    data
  );
  return response.status;
};

const getEmployeeDetails = async (id) => {
  const response = await apiClient.get(`/users/employee/${id}`);
  return response.json();
};

const employeeLocationDetails = async (employeeId, data) => {
  const response = await apiClient.patch(
    `/users/employee/${employeeId}/locations`,
    data
  );
  return response.status;
};

const employeeShiftsDetails = async (employeeId, data) => {
  const response = await apiClient.patch(
    `/users/employee/${employeeId}/shifts`,
    data
  );
  return response.status;
};

const getItemDetails = async (id) => {
  const response = await apiClient.get(`/itemkits/${id}`);
  return response.json();
};

const getItemsById = async (id) => {
  const response = await apiClient.get(`/items/${id}`);
  return response.json();
};

const closeTicket = async (id) => {
  const response = await apiClient.patch(`/tickets/${id}/close`);
  return response.status;
};

const getTicketsbyLocation = async (page, limit, closed, master, type) => {
  const response = await apiClient.get(
    `/tickets?page=${page}&limit=${limit}&closed=${closed}&master=${master}&type=${type}`
  );
  return response.json();
};

const getTicketsbyId = async (id) => {
  const response = await apiClient.get(`/tickets/${id}`);
  return response.json();
};

const getStaffExitTicket = async (page, limit) => {
  const response = await apiClient.get(
    `/tickets/exit?page=${page}&limit=${limit}`
  );
  return response.json();
};

const createStaffingTicket = async (data) => {
  const response = await apiClient.post(`/tickets/staff`, data);
  return response.json();
};

const createTrainingTicket = async (data) => {
  const response = await apiClient.post(`/tickets/training`, data);
  return response.json();
};

const getLocationReviews = async (page, limit) => {
  const response = await apiClient.get(`/reviews?page=${page}&limit=${limit}`);
  return response.json();
};

const getSmsReport = async (page, limit, min_time, max_time) => {
  let filters = ``;
  if (min_time) {
    filters += `&min_time=${min_time}`;
  }
  if (max_time) {
    filters += `&max_time=${max_time}`;
  }
  const response = await apiClient.get(
    `/promotions/report?type=${"promotion"}&start=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const getSmsBalance = async (type) => {
  const response = await apiClient.get(`/promotions/balance?type=${type}`);
  return response.json();
};

const getSmsTemplates = async () => {
  const response = await apiClient.get(`/promotions/templates`);
  return response.json();
};

const completeSale = async (data) => {
  const response = await apiClient.post(`/sales`, data);
  return response.json();
};

const getAppointmentInformation = async (startDate, endDate) => {
  const response = await apiClient.get(
    `/appointments?startDate=${startDate}&endDate=${endDate}`
  );
  return response.json();
};

const createAppointment = async (data) => {
  const response = await apiClient.post(`/appointments`, data);
  return response.status;
};

const salesAppointment = async (data) => {
  const response = await apiClient.post(`/sales/appointment`, data);
  return response.json();
};

const updateAppointment = async (id, data) => {
  const response = await apiClient.patch(`/appointments/${id}`, data);
  return response.status;
};

const deleteAppointment = async (id) => {
  const response = await apiClient.delete(`/appointments/${id}`);
  return response.status;
};

const suspendSale = async (id) => {
  const response = await apiClient.patch(`/sales/suspend/${id}`);
  return response.status;
};

const getSuspendSales = async (page, limit, keyword) => {
  const response = await apiClient.get(
    `/sales/suspend/?page=${page}&limit=${limit}&keyword=${keyword}`
  );
  return response.json();
};

const deleteSuspendSale = async (id) => {
  const response = await apiClient.delete(`/sales/suspend/${id}`);
  return response.status;
};

const getPackageSales = async (page, limit, keyword) => {
  const response = await apiClient.get(
    `/sales/packages/?page=${page}&limit=${limit}`
  );
  return response.json();
};

const updatePackageSale = async (data) => {
  const response = await apiClient.patch(`/sales/packages/redemption`, data);
  return response.json();
};

const getSaleReceipt = async (id) => {
  const response = await apiClient.get(`/sales/receipt/${id}`);
  return response.json();
};
const getPublicSaleReceipt = async (token) => {
  const response = await apiClient.get(`/sales/receipt/open/${token}`);
  return response.json();
};

const createCustomer = async (data) => {
  const response = await apiClient.post(`/customers`, data);
  return response.json();
};

const createRecievings = async (data) => {
  const response = await apiClient.post(`/receivings`, data);
  return response.json();
};

const getCustomerViewSalesById = async (
  page,
  limit,
  id,
  startDate,
  endDate
) => {
  let filters = "";
  if (startDate) {
    filters += `&startDate=${startDate}`;
  }
  if (endDate) {
    filters += `&endDate=${endDate}`;
  }
  const response = await apiClient.get(
    `/customers/get-sales/${id}?page=${page}&limit=${limit}${filters}`
  );
  return response.json();
};

const createItemInformation = async (data) => {
  const response = await apiClient.post(`/items`, data);
  return response.json();
};

const getItemCategories = async (catId) => {
  const response = await apiClient.get(`/items/categories?parentId=${catId}`);
  return response.json();
};

const getCategoriesId = async (categoryId) => {
  const response = await apiClient.get(`/items/categories/${categoryId}`);
  return response.json();
};

const getAllCategories = async () => {
  const response = await apiClient.get("/categories");
  return response.json();
};

const deleteEmployee = async (id) => {
  const response = await apiClient.delete(`/users/employee?employeeId=${id}`);
  return response.status;
};

const createCoupon = async (data) => {
  const response = await apiClient.post(`/coupons`, data);
  return response.json();
};

const storeLocations = async () => {
  const response = await apiClient.get(`/locations/all`);
  return response.json();
};

const UpdateLeadStatus = async (id, body) => {
  const response = await apiClient.patch(`/leads/${id}`, body);
  return response.status;
};

const checkCustomerExist = async (phone, email) => {
  const response = await apiClient.get(
    `/customers/exists?phone=${phone}&email=${email}`
  );
  return response.json();
};

const createLead = async (data) => {
  const response = await apiClient.post(`/leads`, data);
  return response.json();
};

const createLocation = async (data) => {
  const response = await apiClient.post(`/locations`, data);
  return response.json();
};

const createStaffExitTicket = async (data) => {
  const response = await apiClient.post(`/tickets/exit`, data);
  return response.json();
};

const sendOtp = async () => {
  const response = await apiClient.post(`/tickets/send-otp`);
  return response.status;
};

const verifyOtp = async (data) => {
  const response = await apiClient.post(`/tickets/verify-otp`, data);
  const res = await response.json();
  return res;
};

const sendPromotion = async (data) => {
  const response = await apiClient.post(`/promotions/send`, data);
  return response.json();
};

const getCorporateEmployees = async (deleted, isCorporate) => {
  const response = await apiClient.get(
    `/users/employee?deleted=${deleted}&isCorporate=${isCorporate}`
  );
  return response.json();
};

const updateCustomerById = async (id, body) => {
  const response = await apiClient.patch(`/customers/${id}`, body);
  return response.status;
};

const getAllStates = async (id) => {
  const response = await apiClient.get(`/locations/states?id=${id}`);
  return response.json();
};

const getCities = async (id) => {
  const response = await apiClient.get(`/locations/cities?id=${id}`);
  return response.json();
};

const getLocationById = async (id) => {
  const response = await apiClient.get(`/locations/${id}`);
  return response.json();
};

const updateLocationById = async (id, body) => {
  const response = await apiClient.patch(`/locations/${id}`, body);
  return response.json();
};

const getlastLocationSale = async (locationId) => {
  const response = await apiClient.get(`/sales/get-last-sale/${locationId}`);
  return response.json();
};

const getCustomerLastSale = async (id, page, limit, startDate, endDate) => {
  const response = await apiClient.get(
    `/sales/${id}/get-sales?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
  );
  return response.json();
};

const getCustomerSaleHistory = async (id) => {
  const response = await apiClient.get(`/customers/sales/${id}`);
  return response.json();
};

const createFamilyCards = async (data) => {
  const response = await apiClient.post(`/familycards`, data);
  return response.json();
};

const updateFamilyCards = async (id, data) => {
  const response = await apiClient.patch(`/familycards/${id}`, data);
  return response;
};

const redeemFamilyCards = async (id, data) => {
  const response = await apiClient.patch(`/familycards/${id}/redeem`, data);
  return response;
};

const redeemCoupons = async (id, data) => {
  const response = await apiClient.patch(`/coupons/${id}/redeem`, data);

  return response;
};

const createGiftCards = async (data) => {
  const response = await apiClient.post(`/giftcards`, data);

  return response;
};

const updateGiftCards = async (id, data) => {
  const response = await apiClient.patch(`/giftcards/${id}`, data);

  return response;
};

const redeemGiftCards = async (id, data) => {
  const response = await apiClient.patch(`/giftcards/${id}/redeem`, data);

  return response;
};

const getSaleReport = async (startDate, endDate) => {
  const response = await apiClient.get(
    `/reports/sales?from=${startDate}&to=${endDate}`
  );
  return response.json();
};

const getSaleDataReport = async (from, to) => {
  const response = await apiClient.get(
    `/reports/daily-count?startDate=${from}&endDate=${to}`
  );
  return response.json();
};

const getEmployeeReport = async (data) => {
  const response = await apiClient.post(`/reports/employees`, data);
  return response.json();
};

const getEmployeeDataReport = async (from, to, employees) => {
  let filters = ``;

  if (employees) {
    filters += `&employees=${employees}`;
  }
  const response = await apiClient.get(
    `/reports/employees/csv?from=${from}&to=${to}${filters}`
  );
  return response.text();
};

const getSalesDataReport = async (from, to) => {
  const response = await apiClient.get(
    `/reports/sales/csv?from=${from}&to=${to}`
  );
  const res = await response.text();
  return res;
};

const addComment = async (id, data) => {
  const response = await apiClient.post(`/tickets/${id}/comment`, data);
  return await response.text();
};

const addReply = async (id, commentId, data) => {
  const response = await apiClient.post(
    `/tickets/${id}/comment/${commentId}/reply`,
    data
  );
  return await response.text();
};

const getNotifications = async () => {
  const response = await apiClient.get(`/reports/leads/today/overview`);
  return await response.json();
};

const getSuppliers = async (page = 1, limit = 10) => {
  const response = await apiClient.get(
    `/suppliers?page=${page}&limit=${limit}`
  );
  return await response.json();
};

const getSupplierbyName = async (name) => {
  const response = await apiClient.get(`/suppliers/search?name=${name}`);
  return await response.json();
};

const checkAppointmentAvailability = async (data) => {
  const response = await apiClient.post(
    `/appointments/checkAvailability`,
    data
  );
  return await response.json();
};

const customerServicesReport = async (startDate, endDate, itemId) => {
  const response = await apiClient.get(
    `/reports/customer/services?from=${startDate}&to=${endDate}&itemId=${itemId}`
  );
  return await response.json();
};

const customerServicesReportCSV = async (startDate, endDate, itemId) => {
  const response = await apiClient.get(
    `/reports/customer/services/csv?from=${startDate}&to=${endDate}&itemId=${itemId}`
  );
  return await response.blob();
};

const getAllCategoriesNames = async () => {
  const response = await apiClient.get(`/categories/names`);
  return await response.json();
};

const getLowStockReport = async (category) => {
  const response = await apiClient.get(
    `/reports/inventory/low-stock?category=${category}`
  );
  return await response.json();
};

const getInventorySummaryReport = async (category) => {
  const response = await apiClient.get(
    `/reports/inventory/summary?category=${category}`
  );
  return await response.json();
};

const getInventoryReceivingsReport = async (fromDate, toDate, category) => {
  const response = await apiClient.get(
    `/reports/inventory/receivings?fromDate=${fromDate}&toDate=${toDate}&category=${category}`
  );
  return await response.json();
};

const getInventoryReceivingsReportCSV = async (fromDate, toDate, category) => {
  const response = await apiClient.get(
    `/reports/inventory/receivings/csv?fromDate=${fromDate}&toDate=${toDate}&category=${category}`
  );
  return await response.blob();
};

const getCustomerRetentionReports = async (timeInterval) => {
  const response = await apiClient.get(
    `/reports/customer/retention?timeInterval=${timeInterval}`
  );
  return await response.json();
};

const getSalesReportDetailed = async (fromDate, toDate) => {
  const response = await apiClient.get(
    `/reports/sales/detailed?from=${fromDate}&to=${toDate}`
  );
  return await response.json();
};

const getSaleDetails = async (saleId) => {
  const response = await apiClient.get(`/reports/sales/${saleId}`);
  return await response.json();
};

export default {
  getSaleDetails,
  getSalesReportDetailed,
  getCustomerRetentionReports,
  getInventoryReceivingsReportCSV,
  getInventoryReceivingsReport,
  getInventorySummaryReport,
  getLowStockReport,
  getAllCategoriesNames,
  customerServicesReportCSV,
  customerServicesReport,
  searchItemsWithoutSignal,
  createSupplier,
  checkAppointmentAvailability,
  getSupplierbyName,
  getSuppliers,
  getSaleDataReport,
  getNotifications,
  loginEmployee,
  resetEmail,
  resetPassword,
  dashboardSales,
  getUserLocations,
  setUserLocation,
  getLocationLeads,
  getSummaryGraph,
  getLeadFeedback,
  getCustomerData,
  getLeadSource,
  getLeadStatus,
  getCustomerPhone,
  getFamilyCardbyLocation,
  getFamilyCardDetailsbyId,
  getGiftCardDetailsbyId,
  getGiftCardbyLocation,
  getCouponbyLocation,
  getServicesbyLocation,
  getRetailProductbyLocation,
  getItemsbyLocation,
  getLocationData,
  createEmployee,
  getItemDetails,
  getTicketsbyLocation,
  searchItems,
  createStaffingTicket,
  getLocationReviews,
  getSmsReport,
  getSmsBalance,
  completeSale,
  getAppointmentInformation,
  createAppointment,
  salesAppointment,
  updateAppointment,
  deleteAppointment,
  addItemToAppointmentSale,
  addItemKitToAppointmentSale,
  completeAppointmentSaleBySaleId,
  suspendSale,
  getSuspendSales,
  deleteSuspendSale,
  getSaleReceipt,
  createCustomer,
  getCustomerViewSalesById,
  createItemInformation,
  getItemCategories,
  getCategoriesId,
  deleteEmployee,
  createCoupon,
  storeLocations,
  UpdateLeadStatus,
  checkCustomerExist,
  createLead,
  createLocation,
  createTrainingTicket,
  createStaffExitTicket,
  getStaffExitTicket,
  sendOtp,
  verifyOtp,
  getCorporateEmployees,
  getCouponById,
  updateCustomerById,
  getCities,
  getAllStates,
  getLocationById,
  updateLocationById,
  getlastLocationSale,
  getCustomerLastSale,
  getCustomerSaleHistory,
  getItemsById,
  createFamilyCards,
  createGiftCards,
  updateGiftCards,
  updateFamilyCards,
  getCustomerDataByName,
  searchItemsAndItemKits,
  getSmsTemplates,
  sendPromotion,
  updateEmployee,
  getEmployeeDetails,
  employeeLocationDetails,
  employeeShiftsDetails,
  getPackageSales,
  updatePackageSale,
  updateGiftCards,
  getSaleReport,
  getEmployeeReport,
  redeemFamilyCards,
  redeemGiftCards,
  redeemCoupons,
  deleteItemFromAppointmentSale,
  deleteItemKitFromAppointmentSale,
  addItemToSuspendSale,
  addItemKitToSuspendSale,
  deleteItemFromSuspendSale,
  deleteItemKitFromSuspendSale,
  completeSuspendSaleBySaleId,
  changeDetachCustomer,
  getEmployeeDataReport,
  getSalesDataReport,
  getAllCategories,
  getPublicSaleReceipt,
  getTicketsbyId,
  closeTicket,
  addComment,
  addReply,
  createRecievings,
};
