import React from "react";
import clientAdapter from "../../../lib/clientAdapter";
import { useState, useEffect } from "react";
export const CustomerRetentionReport = () => {
  //   const [searchParams] = useSearchParams();
  //   const category = searchParams.get("category");
  //   const fromDate = searchParams.get("fromDate");
  //   const toDate = searchParams.get("toDate");
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState("Last 30 days");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await clientAdapter.getCustomerRetentionReports(
          "last_30_days"
        );
        setData(res);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  async function handleFilterChange(filter) {
    try {
      setData([]);
      if (filter === "last_30_days") setDateFilter("Last 30 days");
      if (filter === "30_to_60_days") setDateFilter("30 to 60 days");
      if (filter === "60_to_120_days") setDateFilter("60 to 120 days");
      if (filter === "120_plus_days") setDateFilter("120+ days");
      const res = await clientAdapter.getCustomerRetentionReports(filter);
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className="container mt-5">
      <div className="card shadow-lg border-0 w-100">
        <div className="card-header bg-dark text-light text-center py-4">
          <h2 className="mb-0">Customer Retention</h2>
        </div>
        <div className="card-body">
          <h1>{dateFilter}</h1>
          <div class="d-flex justify-content-end">
            <button
              onClick={() => {
                handleFilterChange("last_30_days");
              }}
              type="button"
              class="btn btn-primary m-2"
            >
              30 days
            </button>
            <button
              onClick={() => {
                handleFilterChange("30_to_60_days");
              }}
              type="button"
              class="btn btn-primary m-2"
            >
              30 to 60 days
            </button>
            <button
              onClick={() => {
                handleFilterChange("60_to_120_days");
              }}
              type="button"
              class="btn btn-primary m-2"
            >
              60 to 120 days
            </button>
            <button
              onClick={() => {
                handleFilterChange("120_plus_days");
              }}
              type="button"
              class="btn btn-primary m-2"
            >
              120+ days
            </button>
          </div>

          <div className="table-responsive">
            <table className="table table-hover table-bordered">
              <thead className="bg-primary text-light">
                <tr style={{ color: "#FFF" }}>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    First Name
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Last Name
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Phone Number
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Email
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Gender
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Total Purchases
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Total Value of Purchases
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Average Sale Value
                  </th>
                  <th scope="col text-light" style={{ color: "#FFF" }}>
                    Last Visit Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item) => (
                    <tr key={item.phone_number}>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.phone_number}</td>
                      <td>{item.email}</td>
                      <td>{item.gender}</td>
                      <td>{item.total_purchases}</td>
                      <td>{item.total_value_of_purchases}</td>
                      <td>{item.average_sale_value}</td>
                      <td>{item.last_visit_date}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center py-4">
                      Loading. This might take upto a minute.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
