import { useState } from "react";
import { DatatableWrapper } from "react-bs-datatable";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { TableHeader } from "react-bs-datatable";
import { TableBody } from "react-bs-datatable";
import { PaginationOptions } from "react-bs-datatable";
import { useCallback } from "react";
import { Pagination } from "react-bs-datatable";
import { Filter } from "react-bs-datatable";
import clientAdapter from "../../lib/clientAdapter";
import { useEffect } from "react";
import CouponsView from "./CouponsView";
import { SkeletonLoader } from "../../components/loader/SkeletonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

const Coupons = () => {
  const [sortState, setSortState] = useState({});
  const [Count, setCount] = useState(0);
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState();
  const [filterInput, setFilterInput] = useState("");
  const [currentScreen, setCurrentScreen] = useState(0);
  const [loading, setLoading] = useState(false);

  const [cupon, setCupon] = useState({
    isEditing: false,
    editItem: null,
  });

  const header = [
    { title: "Coupon Number", prop: "couponNumber" },
    { title: "Value", prop: "value" },
    { title: "Description", prop: "description" },
    { title: "Customer Name", prop: "firstName" },
    { title: "Active/Inactive", prop: "inactive" },
  ];

  const getCoupons = async (page, limit, sortState, number) => {
    setLoading(true);
    const CouponRes = await clientAdapter.getCouponbyLocation(
      page,
      limit,
      number || null
    );
    CouponRes.coupons.map((c) => {
      c.couponNumber = `${c.couponNumber}`;
      c.value = `${c.value}`;
      c.description = `${c.description}`;
      c.firstName = c.person ? c.person.firstName : "";
      c.inactive = c.inactive ? "Inactive" : "Active";
    });
    setFilteredDataLength(rowsPerPage);
    setData(CouponRes.coupons);
    setCount(CouponRes.count);
    setMaxPage(Math.ceil(CouponRes.count / rowsPerPage));
    setLoading(false);
  };

  const onSortChange = useCallback((nextProp) => {
    setSortState(nextProp);
  }, []);

  const onPaginationChange = useCallback((nextPage) => {
    setCurrentPage(nextPage);
  }, []);

  const onRowsPerPageChange = useCallback((rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    getCoupons(currentPage, rowsPerPage, sortState, filterInput);
  }, [sortState, currentPage, rowsPerPage]);

  const onFilter = () => {
    setFilter(filter);
  };

  const handleSearch = () => {
    getCoupons(currentPage, rowsPerPage, sortState, filterInput);
  };

  const handleFilter = (e) => {
    setFilterInput(e.target.value);
  };

  const onClickCreateNewCoupon = () => {
    setCurrentScreen(1);
  };

  const onClickBack = () => {
    setCurrentScreen(0);
    setCupon({ isEditing: false, editItem: null });
    getCoupons(currentPage, rowsPerPage, sortState, filterInput);
  };

  return (
    <>
      <hr />
      {currentScreen === 0 && (
        <>
          <Row className="d-flex flex-wrap flex-md-nowrap align-items-center">
            <Col className="d-block mb-4 mb-md-0 col-6">
              <div className="">
                <h6 className="h6">
                  List of Coupons{" "}
                  <span
                    style={{
                      backgroundColor: "lightgray",
                      color: "black",
                      border: " 1px solid gray",
                      padding: "5px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "50%",
                      marginLeft: "10px",
                    }}
                  >
                    {Count}
                  </span>
                </h6>
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end align-items-end leadpage-Btn">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  background: "black",
                  color: "#fff",
                  fontSize: 14,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "black", // Change to your desired color
                  },
                }}
                className="customer-title"
                onClick={onClickCreateNewCoupon}
              >
                New Coupon
              </Button>
            </Col>
          </Row>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <DatatableWrapper body={data} headers={header} isControlled>
              <div className="d-flex flex-wrap flex-md-wrap align-items-center ">
                <Filter
                  controlledProps={{
                    filter: "searchbar",
                    onFilter,
                  }}
                />
                <input
                  className="input-search"
                  type="text"
                  width="25%"
                  placeholder="Search Coupons"
                  name="searchbar"
                  style={{ marginLeft: "10px" }}
                  value={filterInput}
                  onChange={(e) => handleFilter(e)}
                />

                <div className="w-8 h-5 " style={{ marginLeft: "4px" }}>
                  <Button
                    className=" search-leads"
                    style={{ border: "1px solid lightGray" }}
                    onClick={handleSearch}
                    sx={{ height: "34px" }}
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "black" }}
                    />
                  </Button>
                </div>
              </div>
              <hr />
              <Table
                className="data-tables shadow-lg"
                hover="true"
                responsive="true"
                width="auto"
                striped="true"
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid lightGray",
                  borderRadius: "10px",
                  marginTop: "15px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <TableHeader
                  controlledProps={{
                    sortState,
                    onSortChange,
                    filteredDataLength,
                  }}
                />
                <TableBody />
              </Table>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-end mb-2 mb-sm-0"
                >
                  <PaginationOptions
                    controlledProps={{
                      filteredDataLength,
                      onRowsPerPageChange,
                      rowsPerPageOptions: [5, 10, 15, 20],
                      rowsPerPage,
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Pagination
                    controlledProps={{
                      currentPage,
                      maxPage,
                      onPaginationChange,
                    }}
                  />
                </Col>
              </Row>
            </DatatableWrapper>
          )}
        </>
      )}

      {currentScreen === 1 && (
        <CouponsView onClickBack={onClickBack} cupon={cupon} />
      )}
    </>
  );
};
export default Coupons;
