import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";
import { Table } from "react-bootstrap";

const CustomerInfo = ({
  getCustomerLastSale,
  selectedCustomer,
  handleEditCustomer,
  handleDetachCustomer,
  updatePackage,
}) => {
  const userLocation = JSON.parse(localStorage.getItem("yumpos_location"));

  const [emailReceipt, setEmailReceipt] = useState(false);

  const handleEmailReceipt = (e) => {
    setEmailReceipt(e.target.checked);
  };

  const familyCards = selectedCustomer?.person?.familycards.filter(
    (card) => card.locationId === userLocation.locationId && !card.inactive
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <Table striped responsive>
            <tbody>
              <tr>
                <td
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "50%",
                  }}
                >
                  <div onClick={getCustomerLastSale}>
                    {" "}
                    {selectedCustomer.person?.firstName ||
                      selectedCustomer.firstName}{" "}
                    {selectedCustomer.person?.lastName ||
                      selectedCustomer?.lastName}
                  </div>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      fontWeight: "lighter",
                    }}
                    onClick={handleEditCustomer}
                  />
                </td>
                <td
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    width: "50%",
                    cursor: "pointer",
                  }}
                >
                  <div onClick={getCustomerLastSale}>
                    {selectedCustomer.person?.phoneNumber ||
                      selectedCustomer.customer?.phoneNumber}
                  </div>
                </td>
              </tr>
              <tr style={{ lineHeight: "3px" }}>
                <td style={{ color: "red", width: "50%" }}>Points:</td>
                <td style={{ width: "50%" }}>
                  {parseInt(selectedCustomer.points).toFixed()}
                </td>
              </tr>
              <tr style={{ lineHeight: "3px" }}>
                <td>LC Number:</td>
                <td>
                  {selectedCustomer.loyaltyCardNumber} {"("}
                  {selectedCustomer.loyaltyCardDiscount}
                  {"%)"}
                </td>
              </tr>
              {familyCards && familyCards.length > 0 && (
                <tr>
                  <td>Membership ID:</td>
                  <td>
                    {familyCards.map((card, index) => (
                      <span key={card.id}>
                        {`${card.familycardNumber} (Rs.${(
                          Math.round(card.value * 100) / 100
                        ).toFixed(2)})`}
                        {index !== familyCards.length - 1 && <span>, </span>}
                      </span>
                    ))}
                  </td>
                </tr>
              )}
              <tr style={{ lineHeight: "3px" }}>
                <td>Total Visits:</td>
                <td>{selectedCustomer.saleCount}</td>
              </tr>
              <tr style={{ lineHeight: "3px" }}>
                <td>Lifetime Purchase Value:</td>
                <td>Rs. {selectedCustomer.lifetimeValue}</td>
              </tr>
              <tr style={{ lineHeight: "3px" }}>
                <td>Birthdate:</td>
                <td>
                  {selectedCustomer.birthday
                    ? moment(selectedCustomer.birthday).format("D-MMM-YYYY")
                    : "NA"}
                </td>
              </tr>
              <tr style={{ lineHeight: "3px" }}>
                <td>Anniversary:</td>
                <td>
                  {selectedCustomer.birthday
                    ? moment(selectedCustomer.anniversary).format("D-MMM-YYYY")
                    : "NA"}
                </td>
              </tr>
              {updatePackage ? null : (
                <tr style={{ lineHeight: "5px" }}>
                  {selectedCustomer.person.email === "" ? (
                    <td>
                      <Button
                        variant="text"
                        startIcon={<FontAwesomeIcon icon={faEdit} />}
                        onClick={handleEditCustomer}
                        sx={{ fontSize: "12px" }}
                      >
                        Update Customer
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={emailReceipt}
                              onChange={handleEmailReceipt}
                              size="small"
                            />
                          }
                          label="E-Mail Receipt?"
                        />
                      </FormGroup>
                    </td>
                  )}

                  <td>
                    <Button
                      variant="text"
                      startIcon={<FontAwesomeIcon icon={faTimes} />}
                      color="error"
                      onClick={handleDetachCustomer}
                      sx={{ fontSize: "12px" }}
                    >
                      Detach
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
