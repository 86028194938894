// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-store-select {
  width: 100%;
  background-color: #FFF;
  color: inherit;
}

.store-list {
  list-style-type: none;
  padding: 0;
}

.store-list-body {
  height: 500px;
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/scss/signin.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,sBAAA;EACA,cAAA;AACJ;;AAEA;EACI,qBAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;AACJ","sourcesContent":[".btn-store-select {\n    width: 100%;\n    background-color: #FFF;\n    color: inherit;\n}\n\n.store-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.store-list-body {\n    height: 500px;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
