/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faCalendar,
  faIdBadge,
  faShoppingCart,
  faPerson,
  faChartBar,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import SaleReport from "./saleReport";
import CustomerReport from "./customerReport";
import EmployeeReport from "./employeeReport";
import { useLocation } from "react-router-dom";
import { InventoryReport } from "./inventoryReports/LowStockForm";
// import { CustomerReport } from "./customerReports/customerReport";
import { useNavigate } from "react-router-dom";
import Categories from "../components/Categories";

export default () => {
  const [show, setShow] = useState({ detailReport: false });
  const [heading, setHeading] = useState("");
  const [icon, setIcon] = useState({ icon: "", class: "" });
  const [currentScreen, setCurrentScreen] = useState(0);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/reports") {
      setCurrentScreen(0);
    }
  }, [location]);

  const getIcon = (element) => {
    switch (element) {
      case "sales":
        return { class: "ti-shopping-cart", icon: faShoppingCart };

      case "employees":
        return { class: "ti-id-badge", icon: faIdBadge };

      case "inventory":
        return { class: "ti-shopping-cart", icon: faChartBar };

      case "customer":
        return { class: "ti-shopping-cart", icon: faPerson };

      default:
        break;
    }
  };

  const handleShow = (element) => {
    setHeading(element);
    setIcon(getIcon(element));
    setShow({ ...show, detailReport: true });
  };

  const onClickDetailedReport = () => {
    switch (heading) {
      case "sales":
        setCurrentScreen(2);
        break;
      case "employees":
        setCurrentScreen(1);
        break;
      case "inventory":
        setCurrentScreen(3);
        break;
      case "customer":
        setCurrentScreen(4);
      default:
        break;
    }
  };

  const onClickBack = () => {
    setCurrentScreen(0);
  };

  const subCategories = {
    sales: [
      {
        name: "Detailed Report",
        onClick: onClickDetailedReport,
        icon: faCalendar,
      },
    ],
    employees: [
      {
        name: "Detailed Report",
        onClick: onClickDetailedReport,
        icon: faCalendar,
      },
    ],
    inventory: [
      {
        name: "low inventory",
        onClick: () => {
          navigate("/inventory/report-form/low-stock");
        },
        icon: faCalendar,
      },
      {
        name: "summary",
        onClick: () => {
          navigate("/inventory/report-form/summary");
        },
        icon: faCalendar,
      },
      {
        name: "Receivings",
        onClick: () => {
          navigate("/inventory/report-form/receiving");
        },
        icon: faCalendar,
      },
    ],
    customer: [
      {
        name: "Detailed Report",
        onClick: onClickDetailedReport,
        icon: faCalendar,
      },
      // {
      //   name: "Service Based Report",
      //   onClick: () => {
      //     navigate(`/customer/report-form/services`);
      //   },
      //   icon: faWrench,
      // },
      // {
      //   name: "Customer Retention",
      //   onClick: () => {
      //     navigate("/customer/report/retention");
      //   },
      //   icon: faWrench,
      // },
    ],
  };

  return (
    <>
      <hr />
      {currentScreen === 0 && (
        <div className="row report-listing mt-3">
          <Typography variant="h5" mb={3} color={"#4A5073"}>
            Reports
          </Typography>
          <div className="col-md-6">
            <div className="panell">
              <div className="panel-body">
                <div className="list-group parent-list">
                  <div
                    className="list-group-item active"
                    id="employees"
                    onClick={() => handleShow("employees")}
                  >
                    <i className="icon ti-id-badge">
                      <FontAwesomeIcon icon={faIdBadge} />
                    </i>
                    Employees
                  </div>
                  <div
                    className="list-group-item active"
                    id="sales"
                    onClick={() => handleShow("sales")}
                  >
                    <i className="icon ti-shopping-cart">
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </i>
                    Sales
                  </div>
                  {/* <div
                    className="list-group-item active"
                    id="sales"
                    onClick={() => handleShow("inventory")}
                  >
                    <i className="icon ti-shopping-cart">
                      <FontAwesomeIcon icon={faChartBar} />
                    </i>
                    Inventory
                  </div> */}
                  <div
                    className="list-group-item active"
                    id="sales"
                    onClick={() => handleShow("customer")}
                  >
                    <i className="icon ti-shopping-cart">
                      <FontAwesomeIcon icon={faPerson} />
                    </i>
                    Customers
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6" id="report_selection">
            <div className="panell">
              <div className="panel-body child-list">
                <h3 className="page-header text-info">
                  <FontAwesomeIcon
                    className={heading ? icon.class : "left-arrow"}
                    icon={heading ? icon.icon : faAngleDoubleLeft}
                  />
                  <span
                    style={{ marginLeft: "8px", textTransform: "capitalize" }}
                  >
                    {heading || "Reports: Make a selection"}
                  </span>
                </h3>
                <hr />

                {show.detailReport && (
                  <div className="list-group employees">
                    {subCategories[heading].map((Category) => (
                      <div
                        key={Category.name} // Adding a unique key is also recommended for mapped elements.
                        className="list-group-items"
                        onClick={Category.onClick}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          className="report-icons"
                          icon={Category.icon}
                        />
                        {Category.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {currentScreen === 1 && <EmployeeReport onClickBack={onClickBack} />}
      {currentScreen === 2 && <SaleReport onClickBack={onClickBack} />}
      {currentScreen === 3 && <InventoryReport onClickBack={onClickBack} />}
      {currentScreen === 4 && <CustomerReport onClickBack={onClickBack} />}
    </>
  );
};
