// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS to hide the time column and header */
.rbc-time-view {
  display: flex !important; 
}
.rbc-agenda-time-cell {
  display: none !important; /* Hide the time header */
}
.rbc-agenda-table .rbc-header:nth-child(2) {
  display: none !important; /* Hide the time header */
}
`, "",{"version":3,"sources":["webpack://./src/style/calendarStyles.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB,EAAE,yBAAyB;AACrD;AACA;EACE,wBAAwB,EAAE,yBAAyB;AACrD","sourcesContent":["/* CSS to hide the time column and header */\n.rbc-time-view {\n  display: flex !important; \n}\n.rbc-agenda-time-cell {\n  display: none !important; /* Hide the time header */\n}\n.rbc-agenda-table .rbc-header:nth-child(2) {\n  display: none !important; /* Hide the time header */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
