export const AppRoutes = {
  // pages
  InventoryReceivingsReportTable: { path: "/inventory/report/receiving" },
  InventoryReceivingsReportForm: { path: "/inventory/report-form/receiving" },
  InventorySummaryForm: { path: "/inventory/report-form/summary" },
  InventorySummaryReport: { path: "/inventory/report/summary" },
  LowInventoryForm: { path: "/inventory/report-form/low-stock" },
  LowInventoryReport: { path: "/inventory/report/low-stock" },
  CustomerServiceReportForm: { path: "/customer/report-form/services" },
  CustomerReport: { path: "/customer-report" },
  CustomerRetentionReport: { path: "/customer/report/retention" },
  Suppliers: { path: "/suppliers" },
  Presentation: { path: "/presen" },
  Dashboard: { path: "/dashboard" },
  Customers: { path: "/customers" },
  CustomerView: { path: "/view" },
  Promotions: { path: "/promotions" },
  PromotionsView: { path: "/promotions-view" },
  Leads: { path: "/leads" },
  Appointments: { path: "/appointments" },
  FamilyCard: { path: "/family-cards" },
  FamilyCardView: { path: "/family-card-view" },
  GiftcardView: { path: "/gift-card-view" },
  TimeClock: { path: "/timeclock" },
  GiftCard: { path: "/gift-cards" },
  Coupons: { path: "/coupons" },
  CouponsView: { path: "/coupons-view" },
  EmployeesView: { path: "/employees-view" },
  RetailProduct: { path: "/retail-product" },
  Reports: { path: "/reports" },
  Items: { path: "/items" },
  ItemKits: { path: "/item-kits" },
  Employees: { path: "/employees" },
  StoreConfig: { path: "/store-config" },
  ExpensesView: { path: "/expenses-view" },
  Locations: { path: "/locations" },
  LocationsView: { path: "/locations-view" },
  Messages: { path: "/messages" },
  Tickets: { path: "/tickets" },
  TicketDetails: { path: "/ticketDetails" },
  Receipt: { path: "/sales/receipt" },
  PublicReceipt: { path: "/sales/receipt/pub" },
  PaymentsView: { path: "/payments-view" },
  Reviews: { path: "/reviews" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  SignIn: { path: "/" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  ResetPasswordNotify: { path: "/reset-password-notify" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },
  LastSaleReceipt: { path: "/sales/last-sale-receipt" },
  Recievings: { path: "/recievings" },
  GiftcardInfo: { path: "/giftcard-info" },
  FamilycardInfo: { path: "/familycard-info" },
  SuspendSales: { path: "/sales/suspend-sales" },
  PackageSale: { path: "/sales/package-sales" },
  BatchSale: { path: "/batch-sale" },
  CustomerDisplay: { path: "/customer-display" },
  Sales: { path: "/sales" },
  TicketGenerator: { path: "/ticket-generator" },
  ItemsViewpage: { path: "/items-view-page" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
