export const familyCardDefaultPackage = [
  { id: 1, balance: 6000, value: 5000, expiry: 1, name: "Pearl (Rs. 5000/-)" },
  {
    id: 2,
    balance: 13000,
    value: 10000,
    expiry: 3,
    name: "Silver (Rs. 10000/-)",
  },
  {
    id: 3,
    balance: 20000,
    value: 15000,
    expiry: 6,
    name: "Gold (Rs. 15000/-)",
  },
  {
    id: 4,
    balance: 30000,
    value: 20000,
    expiry: 9,
    name: "Diamond (Rs. 20000/-)",
  },
  {
    id: 5,
    balance: 50000,
    value: 30000,
    expiry: 12,
    name: "Platinum (Rs. 30000/-)",
  },
];
