const LEGACY_API = "https://api.yumpos.co/api";
// const LEGACY_API = "http://localhost:8085/api";

const getEmployeeSaleCSV = async (fromDate, toDate, employeeId) => {
  const location = JSON.parse(window.localStorage.getItem("yumpos_location"));

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    Payload: {
      locationId: [location.locationId],
      employeeId: employeeId,
      dateFrom: fromDate,
      dateTo: toDate,
      csvExport: 1,
      offset: 0,
      saleType: "all",
      audit: 0,
    },
    Header: {
      Object: "reports",
      Action: "EmployeeReportDetail",
      Version: "v2",
    },
  };

  const response = await fetch(LEGACY_API, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

const getEmployeeSale = async (page, fromDate, toDate, employeeId) => {
  const location = JSON.parse(window.localStorage.getItem("yumpos_location"));

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    Payload: {
      locationId: [location.locationId],
      employeeId: employeeId,
      dateFrom: fromDate,
      dateTo: toDate,
      csvExport: 0,
      offset: page,
      saleType: "all",
      audit: 0,
    },
    Header: {
      Object: "reports",
      Action: "EmployeeReportDetail",
      Version: "v2",
    },
  };

  const response = await fetch(LEGACY_API, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

const getSale = async (page, fromDate, toDate) => {
  const location = JSON.parse(window.localStorage.getItem("yumpos_location"));

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    Payload: {
      locationId: [location.locationId],
      dateFrom: fromDate,
      dateTo: toDate,
      csvExport: 0,
      offset: page,
      saleType: "all",
      audit: 0,
    },
    Header: {
      Object: "reports",
      Action: "SalesReportDetail",
      Version: "v2",
    },
  };

  const response = await fetch(LEGACY_API, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

const getSaleCSV = async (fromDate, toDate) => {
  const location = JSON.parse(window.localStorage.getItem("yumpos_location"));

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    Payload: {
      locationId: [location.locationId],
      dateFrom: fromDate,
      dateTo: toDate,
      csvExport: 1,
      offset: 0,
      saleType: "all",
      audit: 0,
    },
    Header: {
      Object: "reports",
      Action: "SalesReportDetail",
      Version: "v2",
    },
  };

  const response = await fetch(LEGACY_API, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

export default {
  getEmployeeSaleCSV,
  getEmployeeSale,
  getSale,
  getSaleCSV,
};
